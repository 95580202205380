/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-down-left-square-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 16a2 2 0 01-2-2V2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2zm8.096-10.803L6 9.293V6.525a.5.5 0 00-1 0V10.5a.5.5 0 00.5.5h3.975a.5.5 0 000-1H6.707l4.096-4.096a.5.5 0 10-.707-.707"/>',
    },
});
